import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from 'gatsby-image'

const ContentCard = ({ data, className, title, location }) => (
  <div
    className={`shadow-md rounded-md mb-5 ${className}`}
  >
    {title &&
    <div className="text-white px-2 p-1 text-sm bg-primary-darker">
      {title}
    </div>
    }
    <div className="flex">
      <div className="flex-1 flex-grow p-3">
        <Link to={data.path.alias} className="text-xl font-semibold ">
          {data.title}
        </Link>
        {data.field_teaser &&
          <div>
            {data.field_teaser}
          </div>
        }
        {data.field_address &&
          <div className="text-sm">{data.field_address.city}, {data.field_address.state}</div>
        }
        {data.drupal_internal__nid && location?.hostname === 'localhost' &&
          <div className="text-sm">
            <a href={`https://admin.totalbike.com/node/${data.drupal_internal__nid}/edit`}
              rel="noopener noreferrer" target="_blank">
              Edit
            </a>
          </div>
        }
        {data.drupal_internal__tid && location?.hostname === 'localhost' &&
          <div className="text-sm">
            <a href={`https://admin.totalbike.com/taxonomy/term/${data.drupal_internal__tid}/edit`}
              target="_blank" rel="noopener noreferrer">
              Edit
            </a>
          </div>
        }
      </div>
      {data.relationships?.featuredImage?.localFile?.childImageSharp?.fixed &&
        <div className="flex-none p-1 ml-3">
          <Link to={data.path.alias}>
            <Img fixed={data.relationships.featuredImage.localFile.childImageSharp.fixed}
              className="rounded-full items-center shadow-lg"
            />
          </Link>
        </div>
      }
    </div>
  </div>
);

ContentCard.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.object
};

export default ContentCard;
